import imgT1 from '../../assets/images/portfolio/pd-algolia-search.jpg'
import imgT2 from '../../assets/images/portfolio/mso-homepage.jpg'
import imgT4 from '../../assets/images/portfolio/atlantic-spine-center.jpg'
import imgT5 from '../../assets/images/portfolio/atlantic-joint-center.jpg'
import imgT6 from '../../assets/images/portfolio/accountable-ads.jpg'
import imgT7 from '../../assets/images/portfolio/collective-care.jpg'
import imgT8 from '../../assets/images/portfolio/doctor-consulting-dicom.jpg'
import imgT9 from '../../assets/images/portfolio/rig-planet.jpg'
import imgT11 from '../../assets/images/portfolio/react-three-fiber.jpg'
import imgT12 from '../../assets/images/portfolio/pool-rebuild.jpg'
import imgT13 from '../../assets/images/portfolio/book-appointment.jpg'
import imgT14 from '../../assets/images/portfolio/resume.jpg'

const workdata = [
    {
        brand: `Personal`,
        slug: `resume`,
        title: `Resume PWA`,
        categories: ['personal'],
        thumbnail: imgT14,
        link: '/works/resume/'
    },
    {
        brand: `Book Appointment`,
        slug: `book-appointment`,
        title: `Book Appointment`,
        categories: ['web'],
        thumbnail: imgT13,
        link: '/works/book-appointment/'
    },
    {
        brand: `Pain Diagnosed`,
        slug: `pain-diagnosed`,
        title: `Pain Diagnosed`,
        categories: ['full-stack'],
        thumbnail: imgT1,
        link: '/works/pain-diagnosed/'
    },
    // {
    //     brand: `Mountain Spine`,
    //     slug: `mountain-spine`,
    //     title: `Mountain Spine & Orthopedics`,
    //     categories: ['web'],
    //     thumbnail: imgT2,
    //     link: '/works/mountain-spine/'
    // },
    {
        brand: `Atlantic Spine Center`,
        slug: `atlantic-spine-center`,
        title: `Atlantic Spine Center`,
        categories: ['full-stack'],
        thumbnail: imgT4,
        link: '/works/atlantic-spine-center/'
    },
    {
        brand: `Atlantic Joint Center`,
        slug: `atlantic-joint-center`,
        title: `Atlantic Joint Center`,
        categories: ['web'],
        thumbnail: imgT5,
        link: '/works/atlantic-joint-center/'
    },
    {
        brand: `Accountable Ads`,
        slug: `accountable-ads`,
        title: `Accountable Ads`,
        categories: ['full-stack'],
        thumbnail: imgT6,
        link: '/works/accountable-ads/'
    },
    {
        brand: `Collective Care`,
        slug: `collective-care`,
        title: `Collective Care`,
        categories: ['full-stack'],
        thumbnail: imgT7,
        link: '/works/collective-care/'
    },
    {
        brand: `Doctor Consulting`,
        slug: `doctor-consulting`,
        title: `Doctor Consulting`,
        categories: ['full-stack'],
        thumbnail: imgT8,
        link: '/works/doctor-consulting/'
    },
    {
        brand: `Rig Planet`,
        slug: `rig-planet`,
        title: `Rig Planet`,
        categories: ['full-stack'],
        thumbnail: imgT9,
        link: '/works/rig-planet/'
    }
    // {
    //     brand: `React-Three-Fiber`,
    //     slug: `react-three-fiber`,
    //     title: `React Three Fiber`,
    //     categories: ['personal'],
    //     thumbnail: imgT11,
    //     link: '/works/react-three-fiber/'
    // },
    // {
    //     brand: `Pool Rebuild`,
    //     slug: `pool-rebuild`,
    //     title: `Pool Rebuild`,
    //     categories: ['personal'],
    //     thumbnail: imgT12,
    //     link: '/works/pool-rebuild/'
    // }
]

export { workdata }
