import React from 'react'
import { Element } from 'react-scroll'

import PageWrapper from '../components/PageWrapper'
import Hero from '../sections/portfolio/Hero'
import Works from '../sections/portfolio/Works'
import Contact from '../sections/portfolio/Contact'

const IndexPage = () => {
    return (
        <>
            <PageWrapper>
                <Hero />
                <Element name="works">
                    <Works />
                </Element>

                <Contact />
            </PageWrapper>
        </>
    )
}
export default IndexPage
