import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Box, Title, Text } from '../Core'

const WorkBox = styled(Box)`
    border: 4px solid #fff;
    height: 350px;
    overflow: hidden;
    box-shadow: 0 0 30px 2px rgb(0 0 0 / 11%);
    &:hover {
        border-color: yellow;
    }
`
const ChildBox = styled(Box)`
    height: 100%;
    width: 100%;
    transition: all 0.5s;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    ${WorkBox}:hover & {
        transform: scale(1.1);
    }
`

const TextBox = styled(Box)`
    position: absolute;

    overflow: hidden;
    bottom: 0px;
    left: 20px;
    right: 20px;
    opacity: 0;
    border-radius: 8px;
    z-index: 1;
    padding: 1.25rem 1.875rem;
    transition: 0.4s;
    &::before {
        position: absolute;
        content: '';
        background: ${({ theme }) => theme.colors.primary};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        opacity: 0.9;
    }
    ${WorkBox}:hover & {
        bottom: 20px;
        opacity: 1;
    }
`

const DBlock = styled(Link)`
    height: 100%;
`
const TitleLink = styled(Link)`
    color: #fff !important;
    &:hover {
        color: yellow !important;
    }
`

//<img src={workItem.thumbnail} alt="" className="w-100" />
const WorkCard = ({ workItem, link, ...rest }) => {
    const style = {
        backgroundImage: `url(${workItem.thumbnail})`
    }

    return (
        <WorkBox className="position-relative" {...rest}>
            <ChildBox style={style}>
                <DBlock to={link} className="d-block" />
            </ChildBox>
            <TextBox>
                <Text variant="tag" mb={2} color="white">
                    {workItem.categories[0]}
                </Text>
                <Title variant="card">
                    <TitleLink to={link}>{workItem.brand}</TitleLink>
                </Title>
            </TextBox>
        </WorkBox>
    )
}

export default WorkCard
